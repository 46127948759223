import React from 'react';
import treeplanting from '../Assets/Images/tree1.jpg';
import caravan from '../Assets/Images/caravan.jpeg';
import tank from '../Assets/Images/tank.jpeg';
import cidp from '../Assets/Images/cidp.jpeg';
import brick from '../Assets/Images/brick.jpg'

function Climate() {
    const successStories = [
        {
            id: 1,
            title: 'Briquette Production',
            description: [
            'Isiolo Advocacy Network took the initiative to train 10 young people at Ecotaka Solutions on waste management. We launched a briquette production project using paper waste and Mathenge as key raw materials. This innovative approach not only addresses the rising paper waste problem in Isiolo but also controls the spread of the invasive species while providing a cleaner alternative to traditional charcoal. To ensure Mathenge does not regrow after cutting, we supply the community with seedballs made from manure, which sustain grass seeds that thrive in Isiolo’s climate and serve as fodder for livestock. This integrated approach promotes clean energy, restores grazing lands, and provides sustainable livelihoods for young people without harming the environment. Through this initiative, we are tackling climate change while creating economic and ecological resilience in our community.',
            ],
            image: brick,
        },
        {
            id: 2,
            title: 'Environmental Conservation',
            description: 'Over 2,000 trees planted in Isiolo County',
            image: treeplanting,
        },
        {
            id: 3,
            title: 'Camel Caravan',
            description: 'We joined Isiolo Camel Caravan with an aim of promoting peace and championing for climate change awareness in Isiolo and its borders',
            image: caravan,
            cols: 2,  // Set the column span to 2 for this story
        },
        {
            id: 4,
            title: 'Distribution of Water Tanks',
            description: 'We provided water tanks and carried out water piping in Waso Primary and Bulampya schools.',
            image: tank,
            cols: 2,  // Set the column span to 2 for this story
        },
        {
            id: 5,
            title: 'Climate Justice',
            description: 'We participated in the African Climate Week sessions and Advocacy',
            image: cidp,
        },
        
    ];

    return (
        // <div className="container py-2 w-90">
        //     <p className="text-xl text-left text-white mt-20 w-11/12 md:w-1/2 mx-auto mt-36 md:mt-36 mb-4 px-4 leading-relaxed">
        //         Our commitment to Isiolo is reflected in our dedication to conserving the environment and advocating for climate justice. These are key priorities that drive our efforts to create a better future for our community.
        //     </p>
        //     <p className="text-3xl mt-20 mb-20 text-red-500 text-center">Environmental activities</p>
        //     <div className="grid grid-cols-3 sm:grid-cols-2 lg:grid-cols-2 gap-6 ml-auto px-4">
        //         {successStories.map((story) => (
        //             <div
        //                 key={story.id}
        //                 className={`card shadow-lg bg-white ${story.cols === 1 ? 'sm:col-span-2 lg:col-span-2' : ''}`}
        //             >
        //                 <img
        //                     src={story.image}
        //                     alt={story.title}
        //                     className="w-full h-64 object-cover"
        //                 />
        //                 <div className="p-4">
        //                     <h2 className="font-bold text-xl mb-2 text-gray-700">{story.title}</h2>
        //                     <p className="text-black">{story.description}</p>
        //                 </div>
        //             </div>
        //         ))}
        //     </div>
        // </div>
<div className="container py-10 w-90">
    <p className="text-xl text-left text-white mt-20 w-11/12 md:w-1/2 mx-auto mb-6 px-4 leading-relaxed">
        Our commitment to Isiolo is reflected in our dedication to conserving the environment and advocating for climate justice. These are key priorities that drive our efforts to create a better future for our community.
    </p>
    <p className="text-3xl mt-20 mb-20 text-red-500 text-center">Environmental Activities</p>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8 px-4">
        {successStories.map((story) => (
            <div
                key={story.id}
                className={`transform transition-all duration-300 h-fit hover:scale-105 hover:shadow-2xl bg-white rounded-lg overflow-hidden ${story.cols === 1 ? 'lg:col-span-2' : ''}`}
            >
                {/* Image Section */}
                <div className="relative p-6 flex flex-col space-y-4">
                    <img
                        src={story.image}
                        alt={story.title}
                        className="w-full h-1/2 object-cover rounded-t-lg "
                    />
                    <div className="absolute top-4 left-4 bg-white bg-opacity-70 px-3 py-1 text-xs font-semibold text-gray-800 rounded-full shadow-md ">
                        <span className="uppercase">{story.title}</span>
                    </div>
                </div>

                {/* Card Content */}
                <div className="p-6 flex flex-col space-y-4 w-full">
                    <h3 className="text-xl font-semibold text-gray-900">{story.title}</h3>
                    <p className="text-gray-600">{Array.isArray(story.description) ? story.description.join(' ') : story.description}</p>
                    
                </div>
            </div>
        ))}
    </div>
</div>


    );
}

export default Climate;
