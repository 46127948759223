import React from "react";
import {
  FaRegCalendarAlt,
  FaRegCommentDots,
  FaRegThumbsUp,
} from "react-icons/fa";
import kaskaziniImage from "../Assets/Images/ian5.jpg";
import genderAdvocacyImage from "../Assets/Images/ian6.jpg";
import climateActionImage from "../Assets/Images/ian7.jpg";
import brickImage from "../Assets/Images/brick-recent.jpg"

const recentActivities = [
  {
    id: 1,
    title: "Kaskazini Mobile Film Project ",
    description:
      "Empowering Northern Kenya’s Youth Through Digital Storytelling",
    img: kaskaziniImage,
    //   date: 'Dec 8, 2024',
    //   icon: <FaRegCalendarAlt />,
  },
  {
    id: 2,
    title: "Engaging Communities During 16 Days of Activism",
    description:
      "Supporting gender equality through workshops and discussions.",
    img: genderAdvocacyImage,
    //   date: 'Dec 5, 2024',
    //   icon: <FaRegCommentDots />,
  },
  {
    id: 3,
    title: "Innovative Spaces for Advocacy and Creativity",
    description:
      "Learn about our climate action programs and how to contribute.",
    date: "Nov 30, 2024",
    img: climateActionImage,
    //   icon: <FaRegThumbsUp />,
  },
  {
    id: 4,
    title: "Briquette Production.",
    description:
      "Learn about our climate action programs and how to contribute.",
    date: "Nov 30, 2024",
    img: brickImage,
    //   icon: <FaRegThumbsUp />,
  },
];

const Recents = () => {
  return (
    <section className="bg-gray-100 py-12 px-4 md:px-8">
      <div className="max-w-screen-xl mx-auto text-center">
        <h2 className="text-4xl font-bold text-gray-800 mb-8">
          Recent Activities
        </h2>

        {/* Activity Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {recentActivities.map((activity) => (
            <div
              key={activity.id}
              className="bg-white p-6 rounded-lg shadow-lg transition transform hover:scale-105 hover:shadow-xl"
            >
              <div className="flex flex-col items-center mb-4">
                {/* <div className="text-4xl text-blue-500 mr-4">{activity.icon}</div> */}
                <div className="relative mb-4">
                  {/* Image */}
                  <img
                    src={activity.img}
                    alt={activity.title}
                    className="w-full h-48 object-cover rounded-lg"
                  />
                </div>
                <h3 className="text-xl font-semibold text-gray-800">
                  {activity.title}
                </h3>
              </div>
              {/* <p className="text-gray-600 mb-4">{activity.description}</p> */}
              {/* <p className="text-sm text-gray-500">{activity.date}</p> */}
            </div>
          ))}
        </div>

        {/* See More Button */}
        <div className="mt-8">
          <a
            href="/activities"
            className="text-white bg-red-500 py-2 px-6 rounded-full text-lg transition duration-300 hover:bg-red-500"
          > 
            See More
          </a>
        </div>
      </div>
    </section>
  );
};

export default Recents;
