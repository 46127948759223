import React, { useState } from "react";
import aisha from "../Assets/Images/aisha.jpeg";
import moses from "../Assets/Images/moses2.jpeg";
import mathew from "../Assets/Images/mathew.jpg";
import chebet from "../Assets/Images/chebet.jpeg";
import virginia from "../Assets/Images/virginia.jpeg"

function Leadership() {
  const teamMembers = [
    {
      id: 1,
      name: "Moses Thuranira",
      position: "Director",
      image: moses,
      bio: "He is a dynamic filmmaker, youth activist, and community leader hailing from Northern Kenya. Armed with a Bachelor's degree in Education Arts from Laikipia University, Moses is driven by a profound vision: to establish the first Northern Kenya Youth Hub Center. This endeavor serves as a cornerstone of his commitment to equipping young people with technical skills while preserving the region's rich arts and culture. Moses's journey in filmmaking took a significant leap with the co-direction and production of the award-winning documentary 'Between The Rains,' which garnered acclaim at the Tribeca Film Festival. Beyond the camera lens, he founded the Isiolo Advocacy Network, a registered organization dedicated to uplifting his community through grassroots initiatives. Recently elected as the chairperson of the Northern Kenya Filmmakers Association, Moses champions the rights and talents of local artists, fostering a vibrant ecosystem for creativity to thrive. His leadership extends internationally, with participation in esteemed fellowships such as the Points North Institute and Leaders for Leaders Africa program. Notably, Moses has been selected as a finalist for the prestigious Nelson Mandela Fellowship in the USA, underscoring his global impact as a youth advocate and social change catalyst. With each endeavor, Moses Thuranira continues to amplify marginalized voices, drive meaningful action, and inspire the next generation of changemakers in Northern Kenya and beyond.",
    },
    {
      id: 2,
      name: "Virginia Wanjiru",
      position: "Head of Programs",
      image: virginia,
      bio: "Virginia Wanjiru is a dedicated climate advocate and finance lead with a strong background in community development. She excels in coordinating programs and engaging stakeholders to address environmental challenges, leveraging her financial expertise to drive sustainable development initiatives. With a Bachelor of Arts in Communication, Sociology, and Economics from the University of Nairobi, Virginia brings a robust educational foundation to her multifaceted role. Virginia’s professional skills encompass strategic planning, stakeholder engagement, policy development, community empowerment, and financial management. With a deep commitment to social justice and sustainable development, she is passionate about driving positive change and building a more sustainable future for all."
    },
    {
      id: 3,
      name: "Aisha Abdullahi",
      position: "IT",
      image: aisha,
      bio: "Aisha Abdullahi is a software developer with a bachelors degree in Business Imformation Technology and a Full Stack Web development certificate from Develhope, Italy.",
    },
    {
      id: 4,
      name: "Mathew Ngera",
      position: "Graphics Designer",
      image: mathew,
      bio: "Mathew Ngera is a graphic designer, who blends artistic creativity with technical expertise to produce innovative designs. He is proficient in a wide range of design software, and he has a proven ability to handle diverse projects with precision and imaginative flair, spanning from logo design to print materials. Whether conjuring stunning graphics or crafting captivating motion graphics, he transforms ideas into immersive experiences that captivate and inspire. With his expertise, creativity, and unwavering attention to detail, he has proven to be an invaluable asset for any project or organization seeking first-rate graphic design and motion graphics solutions."
    },
    {
      id: 5,
      name: "Jebet Chepkoyo",
      position: "Virtual Reality Expert",
      image: chebet,
      bio: "Jebet is a seasoned brand strategist, grant writer ,and a virtual reality designer with a track record in the industry. She has had the privilege of collaborating in numerous projects across diverse sectors such as technology, Marketing, and media.",
    },
    // Other team members
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const openModal = (member) => {
    setSelectedMember(member);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="container mx-auto py-10 md:py-20 px-4">
      <h1 className="h2 text-center section-title text-white mt-20">
        Meet our team members
      </h1>
      <div
        className="grid grid-cols-1 md:grid-cols-3 gap-8"
        style={{ marginTop: "50px" }}
      >
        {teamMembers.map((member) => (
          <div
            key={member.id}
            className="flex flex-col items-center bg-gray-200 rounded-lg cursor-pointer hover:bg-gray-300 transition duration-300 ease-in-out"
            onClick={() => openModal(member)}
          >
            <img
              src={member.image}
              alt={member.name}
              className="w-32 h-32 object-cover rounded-full mt-4"
            />
            <div className="text-center mt-4">
              <h3 className="text-lg font-semibold text-red-600">
                {member.name}
              </h3>
              <p className="text-gray-500 mb-10">{member.position}</p>
            </div>
          </div>
        ))}
      </div>

      {modalOpen && (
        <div
          className="fixed inset-0 z-50 flex justify-center items-center bg-gray-900 bg-opacity-50 overflow-y-auto"
          style={{ height: "100%" }}
        >
          <div className="bg-white rounded-lg p-6 md:p-8 max-w-4xl mt-20">
            
            <img
              src={selectedMember.image}
              alt={selectedMember.name}
              className="w-100 h-auto rounded-lg mb-4 mx-auto mt-20" style={{marginTop:"100px"}}
            />
            <h2 className="text-xl md:text-2xl font-semibold mb-4 text-red-600 ">
              {selectedMember.name}
            </h2>
            <p className="text-gray-500 mb-4">{selectedMember.position}</p>
            <p className="text-gray-800">{selectedMember.bio}</p>
            <button
              onClick={closeModal}
              className="mt-6 bg-gray-800 text-white px-4 py-2 rounded hover:bg-gray-700 transition duration-300 ease-in-out"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Leadership;
